import React, { useEffect, useState } from "react";
import { DropdownMenu } from "../../components/DropdownMenu/DropdownMenu";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import TutorProfileCard from "../../components/TutorProfileCard/TutorProfileCard";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { getAvailableTutorsData } from "../../backendTools";
import { ReactComponent as FilterIcon } from "../../assets/filter-icon.svg";
import {
  AccountData,
  DropdownOptionType,
  NotificationInfo,
  Subjects,
  TutorData,
  Weekdays,
} from "../../types";
import "./FindTutors.css";

type FindTutorsProps = {
  accountData: AccountData | undefined;
  setLogoShow: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationMessage: React.Dispatch<
    React.SetStateAction<NotificationInfo>
  >;
};

const FindTutors = (props: FindTutorsProps) => {
  const [allTutors, setAllTutors] = useState<TutorData[]>([]);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [filteredResults, setFilteredResults] = useState<TutorData[]>([]);
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    props.setLogoShow(true);
    setLoading(true);
    if (props.accountData) {
      getAvailableTutorsData(props.accountData.email)
        .then((data) => {
          setAllTutors(data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [props.accountData]);

  useEffect(() => {
    updateFilteredTutors();
  }, [allTutors, selectedDays, selectedSubjects, search]);

  const getAvailFilterOptions = (): DropdownOptionType[] => {
    const options: DropdownOptionType[] = [];
    Object.values(Weekdays).map((day) => {
      options.push({
        value: day,
        label: day,
      });
    });
    return options;
  };

  const getSubjectFilterOptions = (): DropdownOptionType[] => {
    const options: DropdownOptionType[] = [];
    Object.values(Subjects).map((sub) => {
      options.push({
        value: sub,
        label: sub,
      });
    });
    return options;
  };

  const updateFilteredTutors = () => {
    // filter by search, days and subjects, show all if no filters
    if (
      search === "" &&
      selectedDays.length === 0 &&
      selectedSubjects.length === 0
    ) {
      setFilteredResults(allTutors);
    }
    const tempFilteredTutors: TutorData[] = [];
    allTutors.forEach((tutor) => {
      if (
        (search === "" ||
          tutor.name.toLowerCase().includes(search.toLowerCase()) ||
          tutor.email.toLowerCase().includes(search.toLowerCase())) &&
        (selectedDays.length === 0 ||
          selectedDays.some((day) => tutor.daysAvailable.includes(day))) &&
        (selectedSubjects.length === 0 ||
          selectedSubjects.some((sub) => tutor.subjects.includes(sub)))
      ) {
        tempFilteredTutors.push(tutor);
      }
    });
    setFilteredResults(tempFilteredTutors);
  };

  return (
    <div className="student-dashboard-screen">
      <h1 className="sd-page-title">Find the perfect tutor for you</h1>
      <div className="sd-filter-row">
        <div className="sd-search-container">
          <input
            className="sd-filter-search"
            placeholder="Search for a tutor"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <FilterIcon
            className="sd-filter-icon"
            onClick={() => setShowFilters(!showFilters)}
          />
        </div>
        <input
          className="sd-filter-search"
          id="desktop"
          placeholder="Search for a tutor"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
        <div
          className={
            "sd-filter-option-container" + (showFilters ? " show" : "")
          }
        >
          <div className="sd-filter-option" id="avail">
            <p>Filter by </p>
            <DropdownMenu
              options={getAvailFilterOptions()}
              placeholder="Availability"
              onChange={(vals: any) => {
                setSelectedDays(vals.map((val: any) => val.value.toString()));
              }}
              multi
            />
          </div>
          <div className="sd-filter-option">
            <p>Filter by </p>
            <DropdownMenu
              options={getSubjectFilterOptions()}
              placeholder="Subject"
              onChange={(vals: any) => {
                setSelectedSubjects(
                  vals.map((val: any) => val.value.toString())
                );
              }}
              multi
            />
          </div>
        </div>
      </div>
      {loading ? (
        <div className="sd-results-loading-container">
          <LoadingIndicator />
        </div>
      ) : (
        <div className="sd-tutor-results">
          <ResponsiveMasonry
            style={{ width: "100%" }}
            columnsCountBreakPoints={{ 100: 1, 700: 2, 1030: 3, 1390: 4 }}
          >
            <Masonry
              gutter={"20px"}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              {filteredResults.map((tutor, index) => (
                <TutorProfileCard
                  accountData={props.accountData}
                  key={"" + index + tutor.email}
                  email={tutor.email}
                  name={tutor.name}
                  subjects={tutor.subjects}
                  grade={tutor.grade}
                  daysAvailable={tutor.daysAvailable}
                  pfp={tutor.profilePicture}
                  setNotificationMessage={props.setNotificationMessage}
                  setNotificationVisible={props.setNotificationVisible}
                  for="find-tutors"
                />
              ))}
            </Masonry>
          </ResponsiveMasonry>

          {filteredResults.length === 0 && (
            <div className="find-tutors-empty-state">
              <h1>No tutors found!</h1>
              <p>
                Try adjusting your filters or searching for a different tutor.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FindTutors;
