import React from "react";
import { AccountData, NotificationInfo } from "../../types";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import TutorDashboard from "../TutorDashboard/TutorDashboard";
import { Navigate } from "react-router-dom";

type MainDashboardProps = {
  accountData: AccountData | undefined;
  loading: boolean;
  setLogoShow: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationMessage: React.Dispatch<
    React.SetStateAction<NotificationInfo>
  >;
};

const MainDashboard = (props: MainDashboardProps) => {
  // useEffect(() => {}, []);
  return !props.loading ? (
    props.accountData?.recentRole === "student" ? (
      <Navigate to="/" replace />
    ) : props.accountData?.recentRole === "tutor" ? (
      <TutorDashboard
        accountData={props.accountData}
        setLogoShow={props.setLogoShow}
        setNotificationMessage={props.setNotificationMessage}
        setNotificationVisible={props.setNotificationVisible}
      />
    ) : (
      <AdminDashboard
        accountData={props.accountData}
        setLogoShow={props.setLogoShow}
        setNotificationMessage={props.setNotificationMessage}
        setNotificationVisible={props.setNotificationVisible}
      />
    )
  ) : (
    <div className="loading-screen">
      <LoadingIndicator />
    </div>
  );
};

export default MainDashboard;
