import React, { useEffect } from "react";
import { NavbarTheme } from "../../types";
import "./LoadingIndicator.css";

type LoadingIndicatorProps = {
  setNavbarTheme?: React.Dispatch<React.SetStateAction<NavbarTheme>>;
};

const LoadingIndicator = (props: LoadingIndicatorProps) => {
  useEffect(() => {
    if (props.setNavbarTheme) {
      props.setNavbarTheme("light");
    }
  }, []);

  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoadingIndicator;
