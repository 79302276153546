import React, { useState } from "react";
import "./ViewMatches.css";
import CustomTable from "../../../components/CustomTable/CustomTable";
import LoadingIndicator from "../../../components/LoadingIndicator/LoadingIndicator";
import { ConnectionData, NavbarTheme, NotificationInfo } from "../../../types";
import { useEffect } from "react";
import { deleteConnection, getAllConnections } from "../../../backendTools";

type ViewMatchesProps = {
  setNotificationVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationMessage: React.Dispatch<
    React.SetStateAction<NotificationInfo>
  >;
};

type ConnectionTableData = {
  id: string;
  tutorName: string;
  tutorEmail: string;
  studentName: string;
  studentEmail: string;
  status: string[];
};

const ViewMatches = (props: ViewMatchesProps) => {
  const [loading, setLoading] = useState(false);
  const [viewMatchesTableData, setViewMatchesTableData] = useState<
    ConnectionTableData[]
  >([]);

  const viewMatchesHeaderLabels = [
    "ID",
    "Tutor Name",
    "Tutor Email",
    "Student Name",
    "Student Email",
    "Status",
  ];

  useEffect(() => {
    fetchConnectionData();
  }, []);

  const fetchConnectionData = async () => {
    setLoading(true);
    await getAllConnections()
      .then((connData) => {
        const tempViewMatchesTableData: ConnectionTableData[] = [];
        for (let i = 0; i < connData.length; i++) {
          tempViewMatchesTableData.push({
            id: connData[i].connectionId as string,
            tutorName: connData[i].tutorName,
            tutorEmail: connData[i].tutorEmail,
            studentName: connData[i].studentName,
            studentEmail: connData[i].studentEmail,
            status: [connData[i].status],
          });
        }
        setViewMatchesTableData(tempViewMatchesTableData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleOnDeleteConnection = async (
    id: string,
    tutorEmail: string,
    studentEmail: string
  ) => {
    setLoading(true);
    await deleteConnection(id, tutorEmail, studentEmail)
      .then(() => {
        props.setNotificationMessage({
          message: "Connection deleted successfully",
          type: "success",
        });
        props.setNotificationVisible(true);
        fetchConnectionData();
      })
      .catch((err) => {
        console.log(err);
        props.setNotificationMessage({
          message: "Error deleting connection",
          type: "error",
        });
        props.setNotificationVisible(true);
        setLoading(false);
      });
  };

  return (
    <div className="ad-fragment">
      <h1 className="ad-fragment-title">View Connections</h1>
      <div className="ad-fragment-inner-content">
        {loading ? (
          <LoadingIndicator />
        ) : viewMatchesTableData.length > 0 ? (
          <CustomTable
            colLabels={viewMatchesHeaderLabels}
            tagCols={["Status"]}
            list={viewMatchesTableData}
            editable={true}
            onDeleteConnection={handleOnDeleteConnection}
            style={{
              maxWidth: "95%",
              margin: "20px",
              height: "fit-content",
              maxHeight: "calc(100vh - 200px)",
              overflow: "auto",
            }}
          />
        ) : (
          <div className="find-tutors-empty-state">
            <h1>No connections found!</h1>
            <p>No one has sent or received a connection request yet.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewMatches;
