import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllUserConnections } from "../../backendTools";
import ConnectionCard from "../../components/ConnectionCard/ConnectionCard";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import TutorProfileCard from "../../components/TutorProfileCard/TutorProfileCard";
import { AccountData, ConnectionData, NotificationInfo } from "../../types";

type YourTutorsProps = {
  accountData: AccountData | undefined;
  setLogoShow: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationMessage: React.Dispatch<
    React.SetStateAction<NotificationInfo>
  >;
};

const YourTutors = (props: YourTutorsProps) => {
  const [allConnections, setAllConnections] = useState<ConnectionData[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAllData();
  }, [props.accountData]);

  const fetchAllData = async () => {
    props.setLogoShow(true);
    if (props.accountData) {
      setLoading(true);
      await getAllUserConnections(props.accountData.email)
        .then((data) => {
          // remove connections where the user is the tutor
          data = data.filter((connection) => {
            if (connection.tutorEmail === props.accountData?.email) {
              return false;
            }
            return true;
          });
          setAllConnections(data);
          if (data.length === 0) {
            props.setNotificationMessage({
              message: "You have no tutors yet!",
              type: "error",
            });
            props.setNotificationVisible(true);
            navigate("/find-tutors");
          }
          setLoading(false);
        })
        .catch((err) => {
          props.setNotificationMessage({
            message: "Something went wrong.",
            type: "error",
          });
          props.setNotificationVisible(true);
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <div className="student-dashboard-screen">
      <h1 className="sd-page-title">Your tutors</h1>
      {loading ? (
        <div className="sd-results-loading-container">
          <LoadingIndicator />
        </div>
      ) : (
        <div
          className="sd-tutor-results"
          style={{ width: "95%", justifyContent: "center" }}
        >
          {allConnections.map((connection) => (
            <ConnectionCard
              key={connection.connectionId}
              data={connection}
              for="student"
              setNotificationVisible={props.setNotificationVisible}
              setNotificationMessage={props.setNotificationMessage}
              refresh={fetchAllData}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default YourTutors;
