import React, { useEffect, useState } from "react";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CustomTable from "../../../components/CustomTable/CustomTable";
import LoadingIndicator from "../../../components/LoadingIndicator/LoadingIndicator";
import { auth } from "../../../firebase";
import {
  addAdmin,
  deleteUser,
  getAllUserData,
  removeAdmin,
} from "../../../backendTools";
import { NotificationInfo, RoleType } from "../../../types";
import "./ManagePermissions.css";

type ManagePermissionsProps = {
  setNotificationVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationMessage: React.Dispatch<
    React.SetStateAction<NotificationInfo>
  >;
};

const ManagePermissions = (props: ManagePermissionsProps) => {
  const [loading, setLoading] = useState(false);
  const userPermheaderLabels = ["Name", "Email", "Role"];
  const [userPermissionsTableData, setUserPermissionsTableData] = useState<
    { name: string; email: string; roles: RoleType[] }[]
  >([]);
  const [modalShow, setModalShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState<{
    name: string;
    email: string;
    roles: RoleType[];
  }>();

  const fetchData = async () => {
    setLoading(true);
    await getAllUserData().then((data) => {
      let tempUPTD: {
        name: string;
        email: string;
        roles: RoleType[];
      }[] = [];
      data.forEach((user) => {
        tempUPTD.push({
          name: user.name,
          email: user.email,
          roles: user.roles,
        });
      });
      setUserPermissionsTableData(tempUPTD);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteUser = async () => {
    if (selectedUser) {
      setModalShow(false);
      setLoading(true);
      await deleteUser(selectedUser.email)
        .then(async () => {
          props.setNotificationMessage({
            message: "User successfully deleted!",
            type: "success",
          });
          props.setNotificationVisible(true);
          setLoading(false);
          if (selectedUser.email === auth.currentUser?.email) {
            auth.signOut();
          } else {
            await fetchData();
          }
        })
        .catch((e) => {
          props.setNotificationMessage({
            message: "An error occurred",
            type: "error",
          });
          setLoading(false);
        });
    }
  };

  const updateAdminAccess = async (
    alreadyHasAccess: boolean,
    email: string
  ) => {
    if (alreadyHasAccess) {
      setLoading(true);
      await removeAdmin(email)
        .then(async () => {
          setLoading(false);
          props.setNotificationMessage({
            message: `${email} is no longer an admin!`,
            type: "success",
          });
          props.setNotificationVisible(true);
          await fetchData();
        })
        .catch((e) => {
          props.setNotificationMessage({
            message: "An error occurred",
            type: "error",
          });
          props.setNotificationVisible(true);

          setLoading(false);
        });
    } else {
      setLoading(true);
      await addAdmin(email)
        .then(async () => {
          setLoading(false);
          props.setNotificationMessage({
            message: `${email} is now an admin!`,
            type: "success",
          });
          props.setNotificationVisible(true);

          await fetchData();
        })
        .catch((e) => {
          props.setNotificationMessage({
            message: "An error occurred",
            type: "error",
          });
          props.setNotificationVisible(true);
          setLoading(false);
        });
    }
  };
  return (
    <>
      <div className="ad-fragment">
        <h1 className="ad-fragment-title">Manage User Permissions</h1>
        <div className="ad-fragment-inner-content">
          {loading ? (
            <LoadingIndicator />
          ) : userPermissionsTableData.length > 0 ? (
            <CustomTable
              colLabels={userPermheaderLabels}
              list={userPermissionsTableData}
              editable={true}
              tagCols={["Role"]}
              onDeleteRow={(index) => {
                setSelectedUser(userPermissionsTableData[index]);
                setModalShow(true);
              }}
              onUpdateAdminAccess={updateAdminAccess}
              style={{
                maxWidth: "95%",
                margin: "20px",
                height: "fit-content",
                maxHeight: "calc(100vh - 200px)",
                overflow: "auto",
              }}
            />
          ) : (
            <div className="find-tutors-empty-state">
              <h1>No users to manage!</h1>
              <p>There are currently no users on Tutor Connect.</p>
            </div>
          )}
        </div>
      </div>
      <CustomModal
        show={modalShow}
        setShow={setModalShow}
        modalContent={
          <div style={{ padding: "10px 6px" }}>
            <h1 style={{ fontSize: "24px", marginBottom: "20px" }}>
              Are you sure you want to delete this user?
            </h1>
            <div className="modal-content-body">
              <div className="input-container">
                <p className="warning-message" style={{ marginBottom: "22px" }}>
                  All data related to <b>{selectedUser?.name}</b>'s account will
                  be permanently deleted.
                </p>
                <div className="warning-multiple-container">
                  <p
                    className="warning-mc-option continue"
                    onClick={handleDeleteUser}
                  >
                    Delete
                  </p>
                  <p
                    className="warning-mc-option back"
                    onClick={() => setModalShow(false)}
                  >
                    Go back
                  </p>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

export default ManagePermissions;
