import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBmyJ4v_4-ZEW6UPxdiDQlLVhKPnslivvw",
  authDomain: "tutor-connect-ecf5f.firebaseapp.com",
  projectId: "tutor-connect-ecf5f",
  storageBucket: "tutor-connect-ecf5f.appspot.com",
  messagingSenderId: "937124776452",
  appId: "1:937124776452:web:5b93f72dc4b785c492c351",
  measurementId: "G-WY0GBNYEYC",
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

const provider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  return await signInWithPopup(auth, provider);
};
