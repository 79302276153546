import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createConnection,
  sendStudentEmail,
  sendTutorEmail,
} from "../../backendTools";
import { AccountData, NotificationInfo, TutorData } from "../../types";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

type ConnectModalProps = {
  accountData: AccountData | undefined;
  tutorInfo: TutorData;
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  setRequestSent: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationMessage: React.Dispatch<
    React.SetStateAction<NotificationInfo>
  >;
};

const ConnectModal = (props: ConnectModalProps) => {
  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleConnect = async () => {
    if (formIsValid() && props.accountData) {
      setLoading(true);
      await sendTutorEmail({
        tutor_email: props.tutorInfo.email,
        student_email: props.accountData.email,
        student_name: props.accountData.name,
        days_available: selectedDays.join(", "),
        subjects: selectedSubjects.join(", "),
        website_url: window.location.origin,
      })
        .then(async () => {
          if (props.accountData) {
            await sendStudentEmail({
              student_email: props.accountData.email,
              tutor_email: props.tutorInfo.email,
              days_available: selectedDays.join(", "),
              subjects: selectedSubjects.join(", "),
            })
              .then(async () => {
                if (props.accountData) {
                  await createConnection({
                    tutorName: props.tutorInfo.name,
                    tutorEmail: props.tutorInfo.email,
                    tutorGrade: props.tutorInfo.grade,
                    studentName: props.accountData.name,
                    studentEmail: props.accountData.email,
                    subjects: selectedSubjects,
                    days: selectedDays,
                    status: "pending",
                  })
                    .then(() => {
                      setLoading(false);
                      props.setRequestSent(true);
                      props.setModalShow(false);
                      props.setNotificationMessage({
                        message: "Your request was sent!",
                        type: "success",
                      });
                      props.setNotificationVisible(true);
                      navigate("/your-tutors");
                    })
                    .catch(() => {
                      setLoading(false);
                      props.setModalShow(false);
                      props.setNotificationMessage({
                        message: "Something went wrong!",
                        type: "error",
                      });
                      props.setNotificationVisible(true);
                    });
                }
              })
              .catch(() => {
                setLoading(false);
                props.setModalShow(false);
                props.setNotificationMessage({
                  message: "Something went wrong!",
                  type: "error",
                });
                props.setNotificationVisible(true);
              });
          }
        })
        .catch(() => {
          setLoading(false);
          props.setModalShow(false);
          props.setNotificationMessage({
            message: "Something went wrong!",
            type: "error",
          });
          props.setNotificationVisible(true);
        });
    }
  };

  const formIsValid = () => {
    return selectedDays.length > 0 && selectedSubjects.length > 0;
  };

  return (
    <div>
      <div
        style={{ padding: "10px 0px" }}
        className={"tpc-inner-content-container" + (loading ? " loading" : "")}
      >
        <h1 style={{ fontSize: "24px", marginBottom: "20px" }}>
          Send a request
        </h1>
        <div className="modal-content-body">
          <div className="input-container">
            <p
              className="connect-modal-message"
              style={{ marginBottom: "22px", textAlign: "center" }}
            >
              {`Send ${
                props.tutorInfo.name.split(" ")[0]
              } a request to tutor you! Once they accept the request, you can coordinate a time and place that works best for both of you.`}
            </p>
            <div className="input-container">
              <p style={{ textAlign: "center" }}>
                Which subjects would you like to be tutored in?
              </p>
              <div
                className="multiple-choice-bubble-container"
                style={{ marginBottom: "22px" }}
              >
                {Object.values(props.tutorInfo.subjects).map((sub, index) => (
                  <p
                    className={
                      "mc-bubble" +
                      (selectedSubjects.includes(sub) ? " active" : "")
                    }
                    key={index}
                    onClick={() => {
                      if (selectedSubjects.includes(sub)) {
                        setSelectedSubjects(
                          selectedSubjects.filter((d) => d !== sub)
                        );
                      } else {
                        setSelectedSubjects([...selectedSubjects, sub]);
                      }
                    }}
                  >
                    {sub}
                  </p>
                ))}
              </div>
            </div>
            <div className="input-container">
              <p style={{ textAlign: "center" }}>
                Which days would you like to be tutored on?
              </p>
              <div
                className="multiple-choice-bubble-container"
                style={{ marginBottom: "22px" }}
              >
                {Object.values(props.tutorInfo.daysAvailable).map(
                  (day, index) => (
                    <p
                      className={
                        "mc-bubble" +
                        (selectedDays?.includes(day) ? " active" : "")
                      }
                      key={index}
                      onClick={() => {
                        if (selectedDays.includes(day)) {
                          setSelectedDays(
                            selectedDays.filter((d) => d !== day)
                          );
                        } else {
                          setSelectedDays([...selectedDays, day]);
                        }
                      }}
                    >
                      {day}
                    </p>
                  )
                )}
              </div>
            </div>
            <div className="connect-modal-multiple-container">
              <p
                className={
                  "connect-modal-option continue" +
                  (formIsValid() ? "" : " disabled")
                }
                onClick={handleConnect}
              >
                Send Request
              </p>
              <p
                className="connect-modal-option back"
                onClick={() => props.setModalShow(false)}
              >
                Go back
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={"tpc-loading-container" + (loading ? " show" : "")}>
        <LoadingIndicator />
      </div>
    </div>
  );
};

export default ConnectModal;
