import React, { ReactElement, useEffect, useState } from "react";
import { NotificationInfo } from "../../types";
import "./CustomNotification.css";

type CustomNotificationProps = {
  message: NotificationInfo;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CustomNotification = (
  props: CustomNotificationProps
): ReactElement<CustomNotificationProps> => {
  useEffect(() => {
    if (props.visible) {
      setTimeout(() => {
        props.setVisible(false);
      }, 3000);
    }
  }, [props.visible]);

  return (
    <div
      className={
        "notification-container" +
        (props.visible ? " visible" : "") +
        " " +
        props.message?.type
      }
    >
      <p className={"notif-message " + props.message?.type}>
        {props.message?.message}
      </p>
    </div>
  );
};
