import React, { useEffect, useState } from "react";
import CustomTable from "../../components/CustomTable/CustomTable";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import SideNavbar from "../../components/SideNavbar/SideNavbar";
import { getAllUserData } from "../../backendTools";
import {
  AccountData,
  AdminDashboardSidebarTab,
  NavbarTheme,
  NotificationInfo,
  RoleType,
} from "../../types";
import "./AdminDashboard.css";
import ManagePermissions from "./ManagePermissions/ManagePermissions";
import VerficationRequests from "./VerificationRequests/VerficationRequests";
import ViewMatches from "./ViewMatches/ViewMatches";

type AdminDashboardProps = {
  accountData: AccountData | undefined;
  setLogoShow: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationMessage: React.Dispatch<
    React.SetStateAction<NotificationInfo>
  >;
};

const AdminDashboard = (props: AdminDashboardProps) => {
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] =
    useState<AdminDashboardSidebarTab>("View Connections");

  useEffect(() => {
    props.setLogoShow(false);
  }, []);

  return (
    <div className="admin-dashboard">
      <SideNavbar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {selectedTab === "Manage Permissions" ? (
        <ManagePermissions
          setNotificationMessage={props.setNotificationMessage}
          setNotificationVisible={props.setNotificationVisible}
        />
      ) : selectedTab === "View Connections" ? (
        <ViewMatches
          setNotificationMessage={props.setNotificationMessage}
          setNotificationVisible={props.setNotificationVisible}
        />
      ) : selectedTab === "Verification Requests" ? (
        <VerficationRequests
          setNotificationMessage={props.setNotificationMessage}
          setNotificationVisible={props.setNotificationVisible}
        />
      ) : null}
    </div>
  );
};

export default AdminDashboard;
