import React, { useState } from "react";
import { ConnectionData, NotificationInfo } from "../../types";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import "./ConnectionCard.css";
import { ReactComponent as PendingIcon } from "../../assets/hourglass-icon.svg";
import { ReactComponent as TutoringIcon } from "../../assets/check-icon.svg";
import { deleteConnection, updateConnectionStatus } from "../../backendTools";

type ConnectionCardProps = {
  data: ConnectionData;
  for: "student" | "tutor";
  setNotificationVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationMessage: React.Dispatch<
    React.SetStateAction<NotificationInfo>
  >;
  refresh?: () => Promise<void>;
};

const ConnectionCard = (props: ConnectionCardProps) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteConnection = () => {
    if (props.data.connectionId) {
      setLoading(true);
      let errMessage = "Error deleting connection";
      let successMessage = "Connection deleted";
      if (props.data.status === "pending") {
        if (props.for === "student") {
          errMessage = "Error deleting connection request";
          successMessage = "Connection request deleted";
        } else if (props.for === "tutor") {
          errMessage = "Error declining connection request";
          successMessage = "Connection request declined";
        }
      } else {
        errMessage = "Error deleting connection";
      }
      deleteConnection(
        props.data.connectionId,
        props.data.tutorEmail,
        props.data.studentEmail
      )
        .then(() => {
          setLoading(false);
          props.setNotificationMessage({
            message: successMessage,
            type: "success",
          });
          props.setNotificationVisible(true);
          if (props.refresh) {
            props.refresh();
          }
        })
        .catch((err) => {
          console.log(err);
          props.setNotificationMessage({
            message: errMessage,
            type: "error",
          });
          props.setNotificationVisible(true);
          setLoading(false);
          if (props.refresh) {
            props.refresh();
          }
        });
    }
  };

  const handleAcceptConnection = () => {
    if (props.data.connectionId) {
      setLoading(true);
      updateConnectionStatus(props.data.connectionId, "tutoring")
        .then(() => {
          setLoading(false);
          props.setNotificationMessage({
            message: "Connection request accepted",
            type: "success",
          });
          props.setNotificationVisible(true);
          if (props.refresh) {
            props.refresh();
          }
        })
        .catch((err) => {
          console.log(err);
          props.setNotificationMessage({
            message: "Error accepting connection request",
            type: "error",
          });
          props.setNotificationVisible(true);
          setLoading(false);
          if (props.refresh) {
            props.refresh();
          }
        });
    }
  };

  return (
    <div
      className={"tpc-container"}
      style={{ position: "relative", marginTop: "50px" }}
    >
      <div className={`cc-status-icon-container ${props.data.status}`}>
        {props.data.status === "pending" ? (
          <PendingIcon className="cc-status-icon pending" />
        ) : (
          <TutoringIcon className="cc-status-icon tutoring" />
        )}
      </div>
      <div
        className={"tpc-inner-content-container" + (loading ? " loading" : "")}
        style={{ marginTop: "40px" }}
      >
        <div className="tpc-header">
          <h2 className="tpc-name">
            {props.for === "student"
              ? props.data.tutorName
              : props.data.studentName}
          </h2>
          {props.for === "student" ? (
            <p className="tpc-grade">{props.data.tutorGrade}</p>
          ) : (
            <div style={{ height: "8px" }}>{/*TODO: Add student grade*/}</div>
          )}
        </div>
        <div className="tpc-divider" />
        <h3 className="tpc-subtitle">Subjects Requested</h3>
        <div className="tpc-subjects">
          {props.data.subjects &&
            props.data.subjects.map((subject, index) => (
              <p className="tpc-subject" key={index}>
                {subject}
              </p>
            ))}
        </div>
        <h3 className="tpc-subtitle">Days Available</h3>
        <div className="tpc-days">
          {props.data.days &&
            props.data.days.map((day, index) => (
              <p className="tpc-day" key={index}>
                {day}
              </p>
            ))}
        </div>
        <h3 className="tpc-subtitle">Connection Status</h3>
        <p className={`tpc-day cc-connection-status ${props.data.status}`}>
          {props.data.status}
        </p>
        {props.for === "tutor" && props.data.status === "pending" && (
          <button
            className="tpc-accept-connection-button"
            onClick={handleAcceptConnection}
          >
            Accept
          </button>
        )}
        <button
          className={`cc-delete-btn ${props.data.status} ${props.for}`}
          onClick={handleDeleteConnection}
        >
          {props.data.status === "pending"
            ? props.for === "student"
              ? "Delete Connection Request"
              : "Decline Connection Request"
            : "Delete Connection"}
        </button>
      </div>
      <div className={"tpc-loading-container" + (loading ? " show" : "")}>
        <LoadingIndicator />
      </div>
    </div>
  );
};

export default ConnectionCard;
