import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { storage } from "../../firebase";
import { updateTutorInfo, updateTutorPfp } from "../../backendTools";
import {
  AccountData,
  Grades,
  NotificationInfo,
  Subjects,
  Weekdays,
} from "../../types";
import ConnectModal from "../CustomModal/ConnectModal";
import CustomModal from "../CustomModal/CustomModal";
import {
  convertToDropdownOptions,
  convertToStringArray,
  DropdownMenu,
} from "../DropdownMenu/DropdownMenu";
import EditTags from "../EditTags/EditTags";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import "./TutorProfileCard.css";

type TutorProfileCardProps = {
  name: string;
  email: string;
  grade: string;
  pfp?: string;
  subjects: string[];
  daysAvailable: string[];
  editable?: boolean;
  accountData: AccountData | undefined;
  style?: React.CSSProperties;
  for?: string;
  setNotificationVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationMessage: React.Dispatch<
    React.SetStateAction<NotificationInfo>
  >;
};

const TutorProfileCard = (props: TutorProfileCardProps) => {
  const [pfp, setPfp] = useState<string>("");
  const [subjects, setSubjects] = useState<string[]>([]);
  const [daysAvailable, setDaysAvailable] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [grade, setGrade] = useState<string>("");
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [requestSent, setRequestSent] = useState<boolean>(false);

  useEffect(() => {
    if (props.subjects && props.subjects.length > 0) {
      setSubjects(props.subjects);
    }
    if (props.daysAvailable && props.daysAvailable.length > 0) {
      setDaysAvailable(props.daysAvailable);
    }
    if (props.grade && props.grade.length > 0) {
      setGrade(props.grade);
    }
    if (props.pfp && props.pfp.length > 0) {
      setPfp(props.pfp);
    }
    setLoading(false);
  }, [props.subjects, props.daysAvailable, props.grade, props.pfp]);

  const handlePfpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && props.email && props.email.length > 0) {
      setLoading(true);
      const file = e.target.files[0];
      const storageRef = ref(storage, `/profilePictures/${props.email}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log("Upload progress: " + percent + "%");
        },
        (err) => {
          console.log(err);
          props.setNotificationMessage({
            message: "An error occurred while uploading your profile picture",
            type: "error",
          });
          props.setNotificationVisible(true);
          setLoading(false);
        },
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            updateTutorPfp(props.email, url)
              .then(() => {
                setPfp(url);
                setLoading(false);
                props.setNotificationMessage({
                  message: "Profile picture changed successfully",
                  type: "success",
                });
                props.setNotificationVisible(true);
              })
              .catch((err) => {
                console.log(err);
                props.setNotificationMessage({
                  message:
                    "An error occurred while uploading your profile picture",
                  type: "error",
                });
                props.setNotificationVisible(true);
                setLoading(false);
              });
          });
        }
      );
    }
  };

  const handleDeletePfp = () => {
    setLoading(true);
    updateTutorPfp(props.email, "")
      .then(() => {
        setPfp("");
        setLoading(false);
        props.setNotificationMessage({
          message: "Profile picture deleted successfully",
          type: "success",
        });
        props.setNotificationVisible(true);
      })
      .catch((err) => {
        console.log(err);
        props.setNotificationMessage({
          message: "An error occurred while deleting your profile picture",
          type: "error",
        });
        props.setNotificationVisible(true);
        setLoading(false);
      });
  };

  const handleSubjectsChange = (e: any) => {
    setLoading(true);
    const newOptions = convertToStringArray(e);
    updateTutorInfo(
      props.email,
      props.name,
      props.grade,
      newOptions,
      daysAvailable
    )
      .then(() => {
        setLoading(false);
        setSubjects(newOptions);
        props.setNotificationMessage({
          message: "Offered subjects changed successfully",
          type: "success",
        });
        props.setNotificationVisible(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        props.setNotificationMessage({
          message: "An error occurred while changing your subjects",
          type: "error",
        });
        props.setNotificationVisible(true);
      });
  };

  const handleDaysChange = (e: any) => {
    setLoading(true);
    const newOptions = convertToStringArray(e);
    updateTutorInfo(props.email, props.name, props.grade, subjects, newOptions)
      .then(() => {
        setLoading(false);
        setDaysAvailable(newOptions);
        props.setNotificationMessage({
          message: "Available days changed successfully",
          type: "success",
        });
        props.setNotificationVisible(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        props.setNotificationMessage({
          message: "An error occurred while changing your subjects",
          type: "error",
        });
        props.setNotificationVisible(true);
      });
  };

  const handleGradeChange = (e: any) => {
    setLoading(true);
    const newOption = e.value;
    updateTutorInfo(props.email, props.name, newOption, subjects, daysAvailable)
      .then(() => {
        setLoading(false);
        setGrade(newOption);
        props.setNotificationMessage({
          message: "Grade changed successfully",
          type: "success",
        });
        props.setNotificationVisible(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        props.setNotificationMessage({
          message: "An error occurred while changing your subjects",
          type: "error",
        });
        props.setNotificationVisible(true);
      });
  };

  const handleConnect = () => {
    setModalShow(true);
  };

  return (
    <>
      <div
        className={
          "tpc-container" +
          (props.editable ? " editable" : "") +
          (props.for ? ` ${props.for}` : "")
        }
        style={props.style ? props.style : {}}
      >
        <div
          className={
            "tpc-inner-content-container" + (loading ? " loading" : "")
          }
        >
          <div className="tpc-header">
            <div className="tpc-pfp-container">
              <img
                className="tpc-pfp"
                src={pfp !== "" ? pfp : require("../../assets/default-pfp.png")}
                alt={`Tutor Profile Picture`}
              />
              {props.editable &&
                (pfp !== "" ? (
                  <img
                    src={require("../../assets/remove-icon.png")}
                    className="remove-tutor-pfp-icon"
                    onClick={handleDeletePfp}
                  />
                ) : (
                  <div className="add-tutor-pfp-container">
                    <label htmlFor="file-input">
                      <img
                        src={require("../../assets/add-icon.png")}
                        className="add-tutor-pfp-icon"
                      />
                    </label>
                    <input
                      className="add-tutor-pfp-input"
                      type="file"
                      id="file-input"
                      accept="image/*"
                      onChange={handlePfpChange}
                    />
                  </div>
                ))}
            </div>
            <h2 className="tpc-name">{props.name}</h2>
            {props.editable ? (
              <DropdownMenu
                options={convertToDropdownOptions(Object.values(Grades))}
                version="editTutorGrade"
                defaultValue={{ value: grade, label: grade }}
                multi={false}
                onChange={handleGradeChange}
              />
            ) : (
              <p className="tpc-grade">{props.grade}</p>
            )}
          </div>
          <div className="tpc-divider" />
          <h3 className="tpc-subtitle">Subjects Offered</h3>
          <div className="tpc-subjects">
            {props.editable ? (
              <EditTags
                options={convertToDropdownOptions(Object.values(Subjects))}
                addBtnText="Add a subject"
                defaultValue={convertToDropdownOptions(subjects)}
                onChange={handleSubjectsChange}
                width="100%"
              />
            ) : (
              subjects &&
              subjects.map((subject, index) => (
                <p className="tpc-subject" key={index}>
                  {subject}
                </p>
              ))
            )}
          </div>
          <h3 className="tpc-subtitle">Days Available</h3>
          <div className="tpc-days">
            {props.editable ? (
              <EditTags
                options={convertToDropdownOptions(Object.values(Weekdays))}
                addBtnText="Add a day"
                defaultValue={convertToDropdownOptions(daysAvailable)}
                onChange={handleDaysChange}
                width="100%"
              />
            ) : (
              daysAvailable &&
              daysAvailable.map((day, index) => (
                <p className="tpc-day" key={index}>
                  {day}
                </p>
              ))
            )}
          </div>
          <button
            className="tpc-connect-button"
            onClick={() => setModalShow(true)}
            disabled={requestSent}
          >
            {requestSent ? "Tutor Request Sent!" : "Connect"}
          </button>
        </div>
        <div className={"tpc-loading-container" + (loading ? " show" : "")}>
          <LoadingIndicator />
        </div>
      </div>
      {!props.editable && (
        <CustomModal
          show={modalShow}
          setShow={setModalShow}
          modalContent={
            <ConnectModal
              accountData={props.accountData}
              setModalShow={setModalShow}
              setNotificationMessage={props.setNotificationMessage}
              setNotificationVisible={props.setNotificationVisible}
              setRequestSent={setRequestSent}
              tutorInfo={{
                email: props.email,
                name: props.name,
                grade: props.grade,
                subjects: subjects,
                daysAvailable: daysAvailable,
                profilePicture: pfp,
                isVerified: true,
              }}
            />
          }
          style={{ width: "fit-content" }}
        />
      )}
    </>
  );
};

export default TutorProfileCard;
