import React, { useEffect, useState } from "react";
import TutorProfileCard from "../../../components/TutorProfileCard/TutorProfileCard";
import { getAllUserConnections, getTutorData } from "../../../backendTools";
import {
  AccountData,
  ConnectionData,
  NotificationInfo,
  TutorData,
} from "../../../types";
import "./VerifiedDashboard.css";
import ConnectionCard from "../../../components/ConnectionCard/ConnectionCard";

type TutorDashboardProps = {
  accountData: AccountData | undefined;
  setNotificationVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationMessage: React.Dispatch<
    React.SetStateAction<NotificationInfo>
  >;
};

const VerifiedDashboard = (props: TutorDashboardProps) => {
  const [tutorData, setTutorData] = useState<TutorData>();
  const [loading, setLoading] = useState(false);
  const [allConnections, setAllConnections] = useState<ConnectionData[]>([]);
  const [pendingConnections, setPendingConnections] = useState<
    ConnectionData[]
  >([]);
  const [selectedPanel, setSelectedPanel] = useState<
    "all" | "pending" | "profile"
  >("all");

  useEffect(() => {
    if (props.accountData) {
      if (selectedPanel === "all" || selectedPanel === "pending") {
        gatherConnectionData();
      } else if (selectedPanel === "profile") {
        setLoading(true);
        getTutorData(props.accountData.email)
          .then((data: any) => {
            if (data) {
              setTutorData(data);
              setLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            props.setNotificationMessage({
              message: "Error loading profile",
              type: "error",
            });
          });
      }
    }
  }, [props.accountData, selectedPanel]);

  const gatherConnectionData = async () => {
    setLoading(true);
    if (props.accountData) {
      await getAllUserConnections(props.accountData.email)
        .then((data) => {
          const tempAllConnections: ConnectionData[] = [];
          const tempPendingConnections: ConnectionData[] = [];
          data.forEach((conn) => {
            if (conn.studentEmail !== props.accountData?.email) {
              if (conn.status === "pending") {
                tempPendingConnections.push(conn);
              } else {
                tempAllConnections.push(conn);
              }
            }
          });
          setAllConnections(tempAllConnections);
          setPendingConnections(tempPendingConnections);
        })
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          props.setNotificationMessage({
            message: "Error loading connections",
            type: "error",
          });
          props.setNotificationVisible(true);
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <div className="verified-tutor-dasboard-screen">
      <h1 className="vtd-page-title">{`Welcome back, ${
        props.accountData?.name.split(" ")[0]
      }!`}</h1>
      <div className="vtd-panel-switch-container">
        <div
          className={
            "vtd-panel-switch-btn" + (selectedPanel === "all" ? " active" : "")
          }
          onClick={() => setSelectedPanel("all")}
        >
          <p className="vtd-psb-text">All connections</p>
          {allConnections.length > 0 && (
            <p className="vtd-psb-badge">
              {allConnections.length > 9 ? "9+" : allConnections.length}
            </p>
          )}
        </div>
        <div
          className={
            "vtd-panel-switch-btn" +
            (selectedPanel === "pending" ? " active" : "")
          }
          onClick={() => setSelectedPanel("pending")}
        >
          <p className="vtd-psb-text">Connections requests</p>
          {pendingConnections.length > 0 && (
            <p className="vtd-psb-badge">
              {pendingConnections.length > 9 ? "9+" : pendingConnections.length}
            </p>
          )}
        </div>
        <div
          className={
            "vtd-panel-switch-btn" +
            (selectedPanel === "profile" ? " active" : "")
          }
          onClick={() => setSelectedPanel("profile")}
        >
          <p className="vtd-psb-text">Edit profile</p>
        </div>
        <div className={`vtd-panel-switch-selector ${selectedPanel}`} />
      </div>
      {selectedPanel === "all" && (
        <div className="vtd-all-panel">
          {allConnections.map((connection) => (
            <ConnectionCard
              key={connection.connectionId}
              data={connection}
              for="tutor"
              setNotificationVisible={props.setNotificationVisible}
              setNotificationMessage={props.setNotificationMessage}
              refresh={gatherConnectionData}
            />
          ))}
          {allConnections.length === 0 && (
            <div className="find-tutors-empty-state">
              <h1>You have no connections!</h1>
              <p>
                Accept any connection requests or hang tight until a student
                requests for your help.
              </p>
            </div>
          )}
        </div>
      )}
      {selectedPanel === "pending" && (
        <div className="vtd-pending-panel">
          {pendingConnections.map((connection) => (
            <ConnectionCard
              key={connection.connectionId}
              data={connection}
              for="tutor"
              setNotificationVisible={props.setNotificationVisible}
              setNotificationMessage={props.setNotificationMessage}
              refresh={gatherConnectionData}
            />
          ))}
          {pendingConnections.length === 0 && (
            <div className="find-tutors-empty-state">
              <h1>You have no connection requests!</h1>
              <p>
                Hang tight for now! You'll receive an email notification when a
                student requests for your help.
              </p>
            </div>
          )}
        </div>
      )}
      {selectedPanel === "profile" && (
        <div className="vtd-profile-panel">
          <TutorProfileCard
            accountData={props.accountData}
            email={props.accountData?.email as string}
            name={tutorData?.name as string}
            grade={tutorData?.grade as string}
            subjects={tutorData?.subjects as string[]}
            daysAvailable={tutorData?.daysAvailable as string[]}
            pfp={tutorData?.profilePicture as string}
            editable={true}
            setNotificationMessage={props.setNotificationMessage}
            setNotificationVisible={props.setNotificationVisible}
          />
        </div>
      )}
    </div>
  );
};

export default VerifiedDashboard;
