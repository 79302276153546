import React, { useEffect } from "react";
import { NavbarTheme } from "../../types";
import "./NotFound.css";

type NotFoundProps = {
  setLogoShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const NotFound = (props: NotFoundProps) => {
  useEffect(() => {
    props.setLogoShow(true);
  }, []);
  return (
    <div className="not-found-screen">
      <h1 className="not-found-text">404 Not Found</h1>
    </div>
  );
};

export default NotFound;
