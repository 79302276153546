export type NotificationInfo =
  | { message: string; type: "success" | "error" }
  | undefined;
export type NavbarTheme = "light" | "dark";

export type RoleType = "student" | "tutor" | "admin";

export enum Grades {
  Freshman = "Freshman",
  Sophomore = "Sophomore",
  Junior = "Junior",
  Senior = "Senior",
}

export enum Weekdays {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday",
}

export enum Subjects {
  Algebra = "Algebra",
  Calculus = "Calculus",
  Geometry = "Geometry",
  ComputerScience = "Computer Science",
  Statistics = "Statistics",
  Macroeconomics = "Macroeconomics",
  PersonalFinancialLiteracy = "Personal Financial Literacy",
  Physics = "Physics",
  Biology = "Biology",
  Chemistry = "Chemistry",
  English = "English",
  Geography = "Geography",
  History = "History",
  Spanish = "Spanish",
  Italian = "Italian",
}

export enum Role {
  Student = "student",
  Tutor = "tutor",
  Admin = "admin",
}

export enum Status {
  PendingVerification = "pending",
  Tutoring = "tutoring",
  Available = "available",
}

export type AccountData = {
  name: string;
  email: string;
  recentRole: RoleType;
  roles: RoleType[];
};

export type TutorData = {
  name: string;
  email: string;
  grade: string;
  daysAvailable: string[];
  subjects: string[];
  isVerified: boolean;
  profilePicture: string;
};

export type ConnectionData = {
  connectionId?: string;
  tutorName: string;
  tutorEmail: string;
  tutorGrade: string;
  studentName: string;
  studentEmail: string;
  subjects: string[];
  days: string[];
  status: "pending" | "tutoring";
};

export type DropdownOptionType = {
  value: string;
  label: string;
};

export type AdminDashboardSidebarTab =
  | "Manage Permissions"
  | "View Connections"
  | "Verification Requests";
