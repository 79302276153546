import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/CustomTable/CustomTable";
import LoadingIndicator from "../../../components/LoadingIndicator/LoadingIndicator";
import {
  getUnverifiedTutors,
  setTutorVerificationStatus,
} from "../../../backendTools";
import { NavbarTheme, NotificationInfo, Status } from "../../../types";
import "./VerificationRequests.css";

type VerficationRequestsProps = {
  setNotificationVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationMessage: React.Dispatch<
    React.SetStateAction<NotificationInfo>
  >;
};

const VerficationRequests = (props: VerficationRequestsProps) => {
  const [loading, setLoading] = useState(false);
  const reqHeaderLabels = ["Email", "Verification Status"];
  const [reqTableData, setReqTableData] = useState<
    { email: string; status: ["Pending"] }[]
  >([]);

  const fetchData = async () => {
    setLoading(true);
    const tempReqTableData: { email: string; status: ["Pending"] }[] = [];
    await getUnverifiedTutors().then((emails: string[]) => {
      for (let i = 0; i < emails.length; i++) {
        tempReqTableData.push({ email: emails[i], status: ["Pending"] });
      }
      setReqTableData(tempReqTableData);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onVerifyTutor = async (email: string) => {
    setLoading(true);
    await setTutorVerificationStatus(email, "verified")
      .then(async () => {
        setLoading(false);
        props.setNotificationMessage({
          message: "Successfully verified tutor!",
          type: "success",
        });
        props.setNotificationVisible(true);
        await fetchData();
      })
      .catch(() => {
        props.setNotificationMessage({
          message: "An error occured",
          type: "error",
        });
        props.setNotificationVisible(true);
      });
  };

  return (
    <div className="ad-fragment">
      <h1 className="ad-fragment-title">Tutor Verification Requests</h1>
      <div className="ad-fragment-inner-content">
        {loading ? (
          <LoadingIndicator />
        ) : reqTableData.length > 0 ? (
          <CustomTable
            colLabels={reqHeaderLabels}
            list={reqTableData}
            editable={true}
            tagCols={["Verification Status"]}
            onVerifyTutor={onVerifyTutor}
            style={{
              maxWidth: "95%",
              margin: "20px",
              height: "fit-content",
              maxHeight: "calc(100vh - 200px)",
              overflow: "auto",
            }}
          />
        ) : (
          <div className="find-tutors-empty-state">
            <h1>No new verification requests!</h1>
            <p>No one has recently signed up to be a tutor.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerficationRequests;
