import React from "react";
import "./UnderConstruction.css";

const UnderConstruction = () => {
  return (
    <div className="under-construction-screen">
      <h1 className="uc-page-title">Under Construction!</h1>
      <img className="uc-image" />
      <h3 className="uc-page-subtitle">
        We're currently working on this page. Check back later once this page is
        complete.
      </h3>
    </div>
  );
};

export default UnderConstruction;
