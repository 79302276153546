import React, { useState } from "react";
import { AdminDashboardSidebarTab } from "../../types";
import { ReactComponent as ManagePermsIcon } from "../../assets/managePermsIcon.svg";
import { ReactComponent as ViewMatchesIcon } from "../../assets/viewMatchesIcon.svg";
import { ReactComponent as Logo } from "../../assets/tutor-connect-logo.svg";
import "./SideNavbar.css";

type SideNavbarProps = {
  selectedTab: AdminDashboardSidebarTab;
  setSelectedTab: React.Dispatch<
    React.SetStateAction<AdminDashboardSidebarTab>
  >;
};

const SideNavbar = (props: SideNavbarProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const tabs: AdminDashboardSidebarTab[] = [
    "View Connections",
    "Verification Requests",
    "Manage Permissions",
  ];
  const icons = {
    "View Connections": <ViewMatchesIcon className="ds-tab-icon" />,
    "Verification Requests": (
      <img
        src={require("../../assets/pendingVerifsIcon.png")}
        className="ds-tab-icon"
      />
    ),
    "Manage Permissions": <ManagePermsIcon className="ds-tab-icon" />,
  };

  return (
    <div className="dashboard-sidebar">
      <Logo className="ds-logo-img" />
      <div className="ds-inner-content">
        {tabs.map((tab, index) => (
          <div key={index}>
            <div
              className={
                "ds-tab" + (props.selectedTab === tab ? " active" : "")
              }
              onClick={() => props.setSelectedTab(tab)}
            >
              {icons[tab]}
              <p>{tab}</p>
              <div className="ds-tab-top-curve" />
              <div className="ds-tab-bottom-curve" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SideNavbar;
