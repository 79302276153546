import React from "react";

const NotVerified = () => {
  return (
    <div className="registered-tutor-screen">
      <div className="td-not-verified-container">
        <div className="td-nv-content-wrapper">
          <img
            src={require("../../../assets/verification.png")}
            className="td-nv-verification-icon"
          />
          <h1>Hang Tight!</h1>
          <h3>
            You're not a verified tutor yet! Once an administrator verifies you,
            you'll be on our list of offcial tutors.
          </h3>
          <h3>
            From there on, you'll get matched with a student looking to get
            tutored!
          </h3>
        </div>
      </div>
    </div>
  );
};

export default NotVerified;
