import React from "react";
import "./CustomTable.css";

type CustomTableProps = {
  colLabels: string[];
  list: any[];
  editable?: boolean;
  tagCols?: string[];
  onVerifyTutor?: (string: string) => void;
  onDeleteRow?: (index: number) => void;
  onUpdateAdminAccess?: (hasAccess: boolean, email: string) => void;
  onDeleteConnection?: (
    id: string,
    tutorEmail: string,
    studentEmail: string
  ) => Promise<void>;
  style?: React.CSSProperties;
};

const CustomTable = (props: CustomTableProps) => {
  return (
    <table cellSpacing="0" className="custom-table" style={props.style}>
      <thead>
        <tr>
          {props.colLabels.map(
            (label, index) => label !== "ID" && <th key={index}>{label}</th>
          )}
          {props.editable && <th>Actions</th>}
        </tr>
      </thead>
      <tbody>
        {Object.values(props.list).map((row, index) => (
          <tr key={index}>
            {Object.values(row).map((col: any, index) => {
              return props.tagCols &&
                props.tagCols.includes(props.colLabels[index]) ? (
                <td key={index} id="editable">
                  {col.map((r: string, index: number) => {
                    return (
                      <p key={index} className={"ct-col-tag " + r}>
                        {r[0].toUpperCase() + r.slice(1)}
                      </p>
                    );
                  })}
                </td>
              ) : (
                props.colLabels[index] !== "ID" && <td key={index}>{col}</td>
              );
            })}
            {props.editable &&
              (props.tagCols?.includes("Role") ? (
                <td>
                  <button
                    className="ct-delete-button"
                    onClick={() => {
                      if (props.onDeleteRow) {
                        props.onDeleteRow(index);
                      }
                    }}
                  >
                    Delete User
                  </button>
                  <button
                    className={
                      "ct-give-admin-button" +
                      (row.roles.includes("admin") ? " remove" : "")
                    }
                    onClick={() => {
                      if (props.onUpdateAdminAccess) {
                        props.onUpdateAdminAccess(
                          row.roles.includes("admin"),
                          row.email
                        );
                      }
                    }}
                  >
                    {row.roles.includes("admin")
                      ? "Remove Admin Access"
                      : "Give Admin Access"}
                  </button>
                </td>
              ) : props.tagCols?.includes("Verification Status") ? (
                <td>
                  <button
                    className="ct-give-admin-button"
                    onClick={() => {
                      if (props.onVerifyTutor) {
                        props.onVerifyTutor(row.email);
                      }
                    }}
                  >
                    Verify User
                  </button>
                </td>
              ) : props.tagCols?.includes("Status") ? (
                <td>
                  <button
                    className="ct-give-admin-button"
                    onClick={() => {
                      if (props.onDeleteConnection) {
                        props.onDeleteConnection(
                          row.id,
                          row.tutorEmail,
                          row.studentEmail
                        );
                      }
                    }}
                  >
                    Delete Connection
                  </button>
                </td>
              ) : (
                <></>
              ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CustomTable;
