import React, { useEffect, useState } from "react";
import Select from "react-select";
import { DropdownOptionType } from "../../types";
import "./DropdownMenu.css";

type DropdownMenuProps = {
  defaultValue?: DropdownOptionType | DropdownOptionType[];
  onChange?: any;
  minWidth?: string;
  options: DropdownOptionType[];
  placeholder?: string;
  multi?: boolean;
  version?: "editTutorGrade";
};

export const DropdownMenu = (props: DropdownMenuProps) => {
  const [selectedOption, setSelectedOption] = useState<
    DropdownOptionType | DropdownOptionType[] | null
  >(props.defaultValue ? props.defaultValue : null);

  useEffect(() => {
    if (props.defaultValue) {
      setSelectedOption(props.defaultValue);
    }
  }, [props.defaultValue]);

  const customStyles = {
    control: (base: any) => ({
      ...base,
      border:
        props.version === "editTutorGrade"
          ? "none"
          : props.multi &&
            selectedOption &&
            (selectedOption as DropdownOptionType[]).length > 0
          ? "2px solid #4766f9"
          : "2px solid #00000033",
      boxShadow: "none",
      cursor: "pointer",
      borderRadius: "6px",
      minWidth: props.minWidth ? props.minWidth : "100%",
      backgroundColor: "transparent",
      maxWidth: "200px",
      margin: props.version === "editTutorGrade" ? "-10px 0px 8px 0px" : "0px",
      transition: "all 0.2s ease",
      "&:hover": {
        boxShadow: "none",
      },
      "@media only screen and (max-width: 480px)": {
        fontSize: !props.version && "12.5px",
        minHeight: !props.version && "0px",
      },
      "@media only screen and (max-width: 440px)": {
        fontSize: !props.version && "16px",
        minHeight: !props.version && "0px",
      },
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: "none",
    }),
    valueContainer: (base: any) => ({
      ...base,
      paddingRight: "0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      whiteSpace: "nowrap",
      // padding: "0px",
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      paddingLeft: props.version === "editTutorGrade" ? "0px" : "4px",
      transition: "all 0.2s ease",
      svg: {
        fill:
          props.multi &&
          selectedOption &&
          (selectedOption as DropdownOptionType[]).length > 0
            ? "#4766f9"
            : "#00000033",
      },
    }),
    menu: (base: any) => ({
      ...base,
      width: "fit-content",
      "@media only screen and (max-width: 480px)": {
        right: !props.version && props.placeholder === "Subject" && "0px",
      },

      "@media only screen and (max-width: 440px)": {
        right: !props.version && props.placeholder === "Subject" && "auto",
      },
    }),
    option: (base: any, state: any) => ({
      ...base,
      backgroundColor: state.isSelected ? "#4766f9" : "#fff",
      color: state.isSelected ? "#fff" : "#000",
      padding: "6px 12px",
      cursor: "pointer",
      transition: "all 0.2s ease",
      "&:hover": {
        backgroundColor: state.isSelected ? "#4766f9" : "#0000000D",
        color: state.isSelected ? "#fff" : "#1e1f4b",
      },
    }),
    clearIndicator: (base: any) => ({
      ...base,
      display: "none",
    }),
    multiValue: (base: any) => ({
      ...base,
    }),
    multiValueRemove: (base: any) => ({
      ...base,
    }),
    placeholder: (base: any) => ({
      ...base,
      transition: "all 0.2s ease",
      color:
        props.multi &&
        selectedOption &&
        (selectedOption as DropdownOptionType[]).length > 0
          ? "#4766f9"
          : "#000000",
      opacity:
        props.multi &&
        selectedOption &&
        (selectedOption as DropdownOptionType[]).length > 0
          ? 1
          : 0.4,
      fontWeight: "500",
    }),
  };

  return (
    <div>
      <Select
        defaultValue={props.defaultValue}
        onChange={(e: any) => {
          setSelectedOption(e);
          if (props.onChange) {
            props.onChange(e);
          }
        }}
        options={props.options}
        isSearchable={false}
        value={selectedOption}
        styles={customStyles}
        placeholder={
          props.placeholder
            ? props.multi &&
              selectedOption &&
              (selectedOption as DropdownOptionType[]).length > 0
              ? props.placeholder +
                " (" +
                (selectedOption as DropdownOptionType[]).length +
                ")"
              : props.placeholder
            : "Select"
        }
        isMulti={props.multi}
        closeMenuOnSelect={!props.multi}
        hideSelectedOptions={props.version === "editTutorGrade"}
        controlShouldRenderValue={props.version === "editTutorGrade"}
        classNamePrefix="select"
      />
    </div>
  );
};

export const convertToDropdownOptions = (
  arr: string[]
): DropdownOptionType[] => {
  const options: DropdownOptionType[] = [];
  if (arr && arr.length > 0) {
    arr.map((item) => {
      options.push({
        value: item,
        label: item,
      });
    });
  }
  return options;
};

export const convertToStringArray = (arr: DropdownOptionType[]): string[] => {
  const options: string[] = [];
  if (arr && arr.length > 0) {
    arr.map((item) => {
      options.push(item.value);
    });
  }
  return options;
};
