import React, { useEffect, useState } from "react";
import {
  checkIfRegisteredTutor,
  setTutorVerificationStatus,
  updateTutorInfo,
} from "../../backendTools";
import {
  AccountData,
  Grades,
  NotificationInfo,
  Subjects,
  Weekdays,
} from "../../types";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import "./TutorDashboard.css";
import { Navigate } from "react-router-dom";
import VerifiedDashboard from "./VerifiedDashboard/VerifiedDashboard";

type TutorDashboardProps = {
  accountData: AccountData | undefined;
  setLogoShow: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationMessage: React.Dispatch<
    React.SetStateAction<NotificationInfo>
  >;
};

const TutorDashboard = (props: TutorDashboardProps) => {
  const [loading, setLoading] = useState(true);
  const [tutorRegistered, setTutorRegistered] = useState(false);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);
  const [selectedGrade, setSelectedGrade] = useState<string | undefined>(
    undefined
  );
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    props.setLogoShow(true);
    if (props.accountData) {
      checkIfRegisteredTutor(props.accountData.email).then((info) => {
        if (info.isTutor) {
          setTutorRegistered(true);
          setIsVerified(info.isVerified);
        } else {
          setTutorRegistered(false);
          setSelectedGrade(info.grade);
          setSelectedDays(info.days);
          setSelectedSubjects(info.subjects);
          setIsVerified(info.isVerified);
        }
        setLoading(false);
      });
    }
  }, [props.accountData, tutorRegistered]);

  const handleNRContinueBtn = () => {
    if (props.accountData?.email) {
      const email = props.accountData?.email;
      const name = props.accountData?.name;
      setLoading(true);
      updateTutorInfo(
        email,
        name,
        selectedGrade as string,
        selectedSubjects,
        selectedDays
      )
        .then(() => {
          setTutorVerificationStatus(email, "pending").then(() => {
            setTutorRegistered(true);
            setLoading(false);
          });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const registerScreen = () => {
    return (
      <div className="td-not-registered-container">
        <h1 className="tdnr-title">
          Answer the following questions to become a tutor!
        </h1>
        <div className="input-container">
          <p>What grade are you in?</p>
          <div className="multiple-choice-bubble-container">
            {Object.values(Grades).map((grade, index) => (
              <p
                className={
                  "mc-bubble" + (selectedGrade === grade ? " active" : "")
                }
                key={index}
                onClick={() => {
                  if (selectedGrade === grade) {
                    setSelectedGrade(undefined);
                  } else {
                    setSelectedGrade(grade);
                  }
                }}
              >
                {grade}
              </p>
            ))}
          </div>
        </div>
        <div className="input-container">
          <p>Which subjects do you prefer tutoring?</p>
          <div className="multiple-choice-bubble-container">
            {Object.values(Subjects).map((sub, index) => (
              <p
                className={
                  "mc-bubble" +
                  (selectedSubjects.includes(sub) ? " active" : "")
                }
                key={index}
                onClick={() => {
                  if (selectedSubjects.includes(sub)) {
                    setSelectedSubjects(
                      selectedSubjects.filter((d) => d !== sub)
                    );
                  } else {
                    setSelectedSubjects([...selectedSubjects, sub]);
                  }
                }}
              >
                {sub}
              </p>
            ))}
          </div>
        </div>
        <div className="input-container">
          <p>Which days work best for tutoring?</p>
          <div className="multiple-choice-bubble-container">
            {Object.values(Weekdays).map((day, index) => (
              <p
                className={
                  "mc-bubble" + (selectedDays?.includes(day) ? " active" : "")
                }
                key={index}
                onClick={() => {
                  if (selectedDays.includes(day)) {
                    setSelectedDays(selectedDays.filter((d) => d !== day));
                  } else {
                    setSelectedDays([...selectedDays, day]);
                  }
                }}
              >
                {day}
              </p>
            ))}
          </div>
        </div>
        <button
          className="tdnr-continue-btn"
          disabled={
            selectedDays.length === 0 ||
            selectedSubjects.length === 0 ||
            selectedGrade === undefined
          }
          onClick={handleNRContinueBtn}
        >
          <p>Continue to my dashboard</p>
        </button>
      </div>
    );
  };

  const tutorDashboardContent = () => {
    return isVerified ? (
      <VerifiedDashboard
        accountData={props.accountData}
        setNotificationMessage={props.setNotificationMessage}
        setNotificationVisible={props.setNotificationVisible}
      />
    ) : (
      <Navigate to="/not-verified-tutor" />
    );
  };

  return (
    <div>
      {loading ? (
        <div className="loading-screen">
          <LoadingIndicator />
        </div>
      ) : (
        <div>
          {tutorRegistered ? (
            <div>{tutorDashboardContent()}</div>
          ) : (
            <div className="non-registered-tutor-screen">
              {registerScreen()}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TutorDashboard;
