import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import { CustomNotification } from "./components/CustomNotification/CustomNotification";
import Navbar from "./components/Navbar/Navbar";
import { auth } from "./firebase";
import { getUserData } from "./backendTools";
import Home from "./screens/Home/Home";
import LoadingIndicator from "./components/LoadingIndicator/LoadingIndicator";
import MainDashboard from "./screens/MainDashboard/MainDashboard";
import NotFound from "./screens/NotFound/NotFound";
import { AccountData, NotificationInfo, RoleType } from "./types";
import NotVerified from "./screens/TutorDashboard/NotVerified/NotVerified";
import YourTutors from "./screens/YourTutors/YourTutors";
import FindTutors from "./screens/FindTutors/FindTutors";
import About from "./screens/About/About";
import UnderConstruction from "./screens/UnderConstruction/UnderConstruction";

export const App = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const [loading, setLoading] = useState(true);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [loginRecentRole, setLoginRecentRole] = useState<RoleType | undefined>(
    undefined
  );
  const [accountData, setAccountData] = useState<AccountData | undefined>(
    undefined
  );
  const [notificationMessage, setNotificationMessage] =
    useState<NotificationInfo>();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [logoShow, setLogoShow] = useState(true);

  useEffect(() => {
    setLoading(false);
    auth.onAuthStateChanged(async (user) => {
      if (user && user.email) {
        if (loginRecentRole) {
          setLoading(true);
          await getUserData(user.email).then((data) => {
            if (data) {
              setAccountData({ ...data, recentRole: loginRecentRole });
            }
            setLoading(false);
          });
        } else {
          setLoading(true);
          await getUserData(user.email).then((data) => {
            if (data) {
              setAccountData(data);
            }
            setLoading(false);
          });
        }
      } else {
        setAccountData(undefined);
      }
    });
  }, [loginRecentRole]);

  useEffect(() => {
    console.log(accountData);
  }, [accountData]);

  return (
    <BrowserRouter>
      <Navbar
        loading={loading}
        setLoading={setLoading}
        setLoginRecentRole={setLoginRecentRole}
        accountData={accountData}
        setAccountData={setAccountData}
        setNotificationVisible={setNotificationVisible}
        setNotificationMessage={setNotificationMessage}
        logoShow={logoShow}
      />
      <CustomNotification
        visible={notificationVisible}
        message={notificationMessage}
        setVisible={setNotificationVisible}
      />
      <Routes>
        <Route
          path="/"
          element={
            loading ? (
              <div className="loading-screen">
                <LoadingIndicator />
              </div>
            ) : accountData ? (
              accountData.recentRole === "student" ? (
                <Navigate to="/your-tutors" replace />
              ) : (
                <Navigate to="/dashboard" replace />
              )
            ) : (
              <Home
                setLogoShow={setLogoShow}
                loading={loading}
                setLoading={setLoading}
                setLoginRecentRole={setLoginRecentRole}
                setNotificationMessage={setNotificationMessage}
                setNotificationVisible={setNotificationVisible}
              />
            )
          }
        />
        <Route path="/home" element={<Navigate to="/" replace />} />
        <Route
          path="/dashboard"
          element={
            accountData ? (
              <MainDashboard
                accountData={accountData}
                loading={loading}
                setLogoShow={setLogoShow}
                setNotificationMessage={setNotificationMessage}
                setNotificationVisible={setNotificationVisible}
              />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/your-tutors"
          element={
            accountData ? (
              <YourTutors
                accountData={accountData}
                setLogoShow={setLogoShow}
                setNotificationMessage={setNotificationMessage}
                setNotificationVisible={setNotificationVisible}
              />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        <Route
          path="/find-tutors"
          element={
            accountData ? (
              <FindTutors
                accountData={accountData}
                setLogoShow={setLogoShow}
                setNotificationMessage={setNotificationMessage}
                setNotificationVisible={setNotificationVisible}
              />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />

        <Route
          path="/not-verified-tutor"
          element={accountData ? <NotVerified /> : <Navigate to="/" replace />}
        />

        <Route
          path="/about"
          element={
            accountData ? <UnderConstruction /> : <Navigate to="/" replace />
          }
        />

        <Route path="*" element={<NotFound setLogoShow={setLogoShow} />} />
      </Routes>
    </BrowserRouter>
  );
};
