import React, { useEffect, useState } from "react";
import CustomModal from "../../components/CustomModal/CustomModal";
import LoginModal from "../../components/CustomModal/LoginModal";
import { NotificationInfo, RoleType } from "../../types";
import { ReactComponent as SectionDivider } from "../../assets/sectionDivider.svg";
import "./Home.css";

type HomeProps = {
  setLogoShow: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  setNotificationVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationMessage: React.Dispatch<
    React.SetStateAction<NotificationInfo>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setLoginRecentRole: React.Dispatch<
    React.SetStateAction<RoleType | undefined>
  >;
};

const Home = (props: HomeProps) => {
  const [modalShow, setModalShow] = useState(false);

  const handleGetStarted = () => {
    props.setLogoShow(true);
    setModalShow(true);
  };

  useEffect(() => {
    props.setLogoShow(true);
  }, []);

  return (
    <>
      <div className="home-screen">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="home-screen-content"
        >
          <div className="home-header-content">
            <div className="home-column" id="left">
              <h1 className="home-header-title">Welcome to Tutor Connect.</h1>
              <h3 className="home-header-subtitle">
                Instantly connect with tutors from Secaucus High School's
                National Honor Society and Mu Alpha Theta
              </h3>
              <button
                className="home-get-started-btn"
                onClick={handleGetStarted}
              >
                Get Started
              </button>
            </div>
            <div className="home-column" id="right">
              <img
                src={require("../../assets/home-banner-2.jpg")}
                className="home-banner-img"
                alt="home-banner"
              />
            </div>
          </div>
          <SectionDivider className="home-section-divider" />
        </div>
        <div className="footer-container">
          <img
            src={require("../../assets/shs-logo.png")}
            className="home-footer-logo"
            onClick={() => window.open("https://shs.sboe.org/")}
          />
          <img
            src={require("../../assets/nhs-logo.png")}
            className="home-footer-logo"
            onClick={() => window.open("https://www.nhs.us/")}
          />
          <p className="home-footer-credits-tag">
            {"Created by "}
            <a href={"https://www.krushaybhavsar.com/"}>{"Krushay Bhavsar"}</a>
            {" 2022"}
          </p>
        </div>
      </div>
      <CustomModal
        modalContent={
          <LoginModal
            setLoading={props.setLoading}
            setShow={setModalShow}
            setLoginRecentRole={props.setLoginRecentRole}
            setNotificationMessage={props.setNotificationMessage}
            setNotificationVisible={props.setNotificationVisible}
          />
        }
        setShow={setModalShow}
        show={modalShow}
      />
    </>
  );
};

export default Home;
