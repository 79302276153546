import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { DropdownOptionType } from "../../types";
import "./EditTags.css";
import { components } from "react-select";

type EditTagsProps = {
  defaultValue: DropdownOptionType[];
  onChange?: any;
  options: DropdownOptionType[];
  addBtnText: string;
  width?: string;
};

const EditTags = (props: EditTagsProps) => {
  const [selectedOption, setSelectedOption] = useState<
    DropdownOptionType[] | null
  >(null);
  const [atMaxTags, setAtMaxTags] = useState<boolean>(false);
  const [hideDeleteIcon, setHideDeleteIcon] = useState<boolean>(false);

  useEffect(() => {
    if (props.defaultValue) {
      setSelectedOption(props.defaultValue);
      if (props.defaultValue.length === props.options.length) {
        setAtMaxTags(true);
      } else {
        setAtMaxTags(false);
      }
      if (props.defaultValue.length === 1) {
        setHideDeleteIcon(true);
      } else {
        setHideDeleteIcon(false);
      }
    }
  }, [props.defaultValue]);

  useEffect(() => {
    if (selectedOption && selectedOption.length === props.options.length) {
      setAtMaxTags(true);
    } else {
      setAtMaxTags(false);
    }
  }, [selectedOption]);

  const customStyles = {
    control: (base: any) => ({
      ...base,
      border: "none",
      boxShadow: "none",
      borderRadius: "6px",
      padding: "0px",
      margin: "0px",
      transition: "all 0.2s ease",
      width: props.width ? props.width : "300px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        boxShadow: "none",
      },
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      padding: "0px",
      margin: "0px",
      display: "none",
    }),
    valueContainer: (base: any) => ({
      ...base,
      padding: "0px",
      margin: "0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      whiteSpace: "nowrap",
      width: props.width ? props.width : "300px",
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      padding: "0px",
      margin: "0px",
      transition: "all 0.2s ease",
      svg: {
        display: "none",
      },
    }),
    menu: (base: any) => ({
      ...base,
      minWidth: "fit-content",
      padding: "0px",
      margin: "0px",
      width: "300px",
      textAlign: "center",
      left: "50%",
      transform: "translateX(-50%)",
    }),
    option: (base: any, state: any) => ({
      ...base,
      backgroundColor: state.isSelected ? "#4766f9" : "#fff",
      color: state.isSelected ? "#fff" : "#000",
      padding: "6px 12px",
      cursor: "pointer",
      transition: "all 0.2s ease",
      "&:hover": {
        backgroundColor: state.isSelected ? "#4766f9" : "#0000000D",
        color: state.isSelected ? "#fff" : "#1e1f4b",
      },
    }),
    clearIndicator: (base: any) => ({
      ...base,
      display: "none",
    }),
    multiValue: (base: any) => ({
      ...base,
      backgroundColor: "#fff",
      color: "#1e1f4b",
      margin: "4px 2.5px",
      textAlign: "center",
      whiteSpace: "nowrap",
      border: "2px solid #ccc",
      padding: "0px 2px",
      paddingRight: hideDeleteIcon ? "6px" : "0px",
      borderRadius: "100px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      div: {
        fontSize: "14px !important",
      },
    }),
    multiValueRemove: (base: any) => ({
      ...base,
      background: "rgba(255, 255, 255, 0)",
      cursor: "pointer",
      transition: "all 0.2s ease",
      display: hideDeleteIcon ? "none" : "flex",
      "&:hover": {
        background: "rgba(255, 255, 255, 0)",
        transform: "scale(1.25)",
      },
    }),
    placeholder: (base: any) => ({
      ...base,
      transition: "all 0.2s ease",
      opacity: "0.8",
      fontWeight: "normal",
      textAlign: "center",
    }),
  };

  const addBtnText = props.addBtnText;

  const SelectMenuButton = (props: any) => {
    return (
      <components.ValueContainer {...props}>
        {props.children}
        {!atMaxTags && (
          <p className="tpc-subject add">
            <img
              className="tpc-add-tag-icon"
              src={require("../../assets/add-icon.png")}
            />
            {addBtnText}
          </p>
        )}
      </components.ValueContainer>
    );
  };

  return (
    <div>
      <Select
        defaultValue={props.defaultValue}
        onChange={(e: any) => {
          if (e.length !== 0) {
            if (e.length === 1) {
              setHideDeleteIcon(true);
            } else {
              setHideDeleteIcon(false);
            }
            setSelectedOption(e);
            if (props.onChange) {
              props.onChange(e);
            }
          }
        }}
        options={props.options}
        value={selectedOption}
        styles={customStyles}
        isMulti={true}
        placeholder=""
        isSearchable={false}
        openMenuOnFocus={false}
        components={{ ValueContainer: SelectMenuButton }}
        closeMenuOnSelect={true}
        backspaceRemovesValue={false}
        noOptionsMessage={() => "Maximum tags reached"}
      />
    </div>
  );
};

export default EditTags;
