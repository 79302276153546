import React, { ReactElement, useState } from "react";
import "./Navbar.css";

import { auth } from "../../firebase";
import { Link, useLocation } from "react-router-dom";
import {
  AccountData,
  NavbarTheme,
  NotificationInfo,
  RoleType,
} from "../../types";
import CustomModal from "../CustomModal/CustomModal";
import LoginModal from "../CustomModal/LoginModal";
import { ReactComponent as Logo } from "../../assets/tutor-connect-logo.svg";

type NavbarProps = {
  setNotificationVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationMessage: React.Dispatch<
    React.SetStateAction<NotificationInfo>
  >;
  setAccountData: React.Dispatch<React.SetStateAction<AccountData | undefined>>;
  accountData: AccountData | undefined;
  loading: boolean;
  logoShow?: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setLoginRecentRole: React.Dispatch<
    React.SetStateAction<RoleType | undefined>
  >;
};

const defaultProps = {
  logoShow: true,
};

function Navbar(props: NavbarProps): ReactElement<NavbarProps> {
  const [modalShow, setModalShow] = useState(false);
  const [openHamburger, setOpenHamburger] = useState(false);

  const handleLoginLogout = () => {
    if (props.accountData) {
      auth.signOut().then(() => {
        props.setNotificationMessage({
          message: "Successfully signed out!",
          type: "success",
        });
        props.setNotificationVisible(true);
        props.setAccountData(undefined);
      });
    } else {
      setModalShow(true);
    }
    setOpenHamburger(false);
  };

  const path = useLocation().pathname;
  const darkNavbarPaths: string[] = ["/", "/home", "/not-verified-tutor"];
  const getNavbarTheme = (): NavbarTheme => {
    if (darkNavbarPaths.includes(path)) {
      return "dark";
    }
    return "light";
  };

  const getNavLinks = (): { text: string; link: string }[] => {
    if (props.accountData) {
      if (props.accountData.recentRole === "student") {
        return [
          { text: "Your Tutors", link: "/your-tutors" },
          { text: "Find a Tutor", link: "/find-tutors" },
          // {text: "About", link: "/about"},
        ];
      } else if (
        props.accountData.recentRole === "tutor" ||
        props.accountData.recentRole === "admin"
      ) {
        return [
          { text: "Dashboard", link: "/dashboard" },
          // {text: "About", link: "/about"},
        ];
      }
    }
    return [];
  };

  return (
    <>
      <div
        className={
          "navbar" +
          (getNavbarTheme() === "dark" ? " dark" : "") +
          (!props.logoShow ? " logo-hidden" : "")
        }
      >
        {props.logoShow && (
          <Link className="navbar-logo-container" to="/">
            <Logo className="navbar-logo-img" />
            <p className="navbar-logo-text">Tutor Connect</p>
          </Link>
        )}
        <div className={"navbar-links-container"}>
          {getNavLinks().map((linkData, idx) => (
            <Link className="navbar-link" to={linkData.link} key={idx}>
              {linkData.text}
            </Link>
          ))}
          <p
            className="navbar-link"
            id={props.accountData ? "logout" : "login"}
            onClick={handleLoginLogout}
          >
            {props.accountData ? "Sign out" : "Sign in"}
          </p>
        </div>

        {props.accountData ? (
          <div
            className={`hamburger-menu${openHamburger ? " active" : ""} ${
              getNavbarTheme() === "dark" ? "dark" : ""
            }`}
            onClick={() => setOpenHamburger(!openHamburger)}
          >
            <span className="bar1"></span>
            <span className="bar2"></span>
            <span className="bar3"></span>
          </div>
        ) : (
          <p
            className="navbar-link mobile"
            id={props.accountData ? "logout" : "login"}
            onClick={handleLoginLogout}
          >
            {props.accountData ? "Sign out" : "Sign in"}
          </p>
        )}
      </div>

      <div className={`side-menu${openHamburger ? " active" : ""}`}>
        <div className="links-active">
          <ul>
            {getNavLinks().map((linkData, idx) => (
              <li key={idx}>
                <Link
                  className="navbar-link"
                  to={linkData.link}
                  onClick={() => setOpenHamburger(false)}
                >
                  {linkData.text}
                </Link>
              </li>
            ))}
            <li>
              <a className="navbar-link" onClick={handleLoginLogout}>
                {props.accountData ? "Sign out" : "Sign in"}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div
        className={"modal-background navbar" + (openHamburger ? " active" : "")}
        onClick={() => setOpenHamburger(false)}
      ></div>

      <CustomModal
        show={modalShow}
        setShow={setModalShow}
        modalContent={
          <LoginModal
            setShow={setModalShow}
            setLoading={props.setLoading}
            setLoginRecentRole={props.setLoginRecentRole}
            setNotificationMessage={props.setNotificationMessage}
            setNotificationVisible={props.setNotificationVisible}
          />
        }
      />
    </>
  );
}

Navbar.defaultProps = defaultProps;

export default Navbar;
